/* eslint-disable*/
const { toDecimal, toFraction } = require('fraction-parser');
// import { toDecimal, toFraction } from 'fraction-parser';
// const generateBreadcrumbs = require('../../utils/breadcrumbs.js');

const toggleCont = document.querySelector('.toggle-container');
const toggleBtns = document.querySelectorAll('.toggle');

const ingredientContainer = document.querySelectorAll('.ingredientContainer');
const yieldBase = document.getElementById('yields');
// console.log(modifyNumbers("1/4 cup", 2));
const checkboxes = document.querySelectorAll('.custom-control-input');

const printBtn = document.getElementById('printBtn');
const returnLink = document.getElementById('returnLink');

const masonryItems = document.querySelectorAll('.masonry-item');

//masonry items

// if (masonryItems)
//   masonryItems.forEach((item, index) => {
//     // Generate random values for grid-row and grid-column, ensuring they're within reasonable bounds
//     const randomRowSpan = Math.floor(Math.random() * 3) + 1; // Random value between 1 and 3
//     const randomColumnSpan = Math.floor(Math.random() * 2) + 1; // Random value between 1 and 2

//     item.style.gridColumn = `span ${randomColumnSpan}`;
//     item.style.gridRow = `span ${randomRowSpan}`;
//   });

//return link
if (returnLink)
  returnLink.addEventListener('click', (e) => {
    e.preventDefault();
    window.history.back();
  });

// print button
if (printBtn)
  printBtn.addEventListener('click', () => {
    window.print();
  });

// checkboxes for strikethrough
if (checkboxes)
  checkboxes.forEach((box) => {
    box.addEventListener('click', () => {
      const spans = box.parentElement.parentElement.lastChild.childNodes;
      spans.forEach((span) => {
        if (box.checked == true) {
          span.classList.add('strikethrough');
        } else {
          span.classList.remove('strikethrough');
        }
      });
    });
  });

// toggleButtons for multiplier
if (toggleCont) {
  toggleCont.dataset.setMultiplier = 1;
  changeYield(1);
  changeIngredients(1);
}
// toggleButtons for multiplier

if (toggleBtns)
  toggleBtns.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      e.target.parentNode.childNodes.forEach((element) => {
        element.classList.remove('toggle-active');
      });
      e.target.classList.add('toggle-active');
      e.target.parentNode.dataset.setMultiplier = e.target.dataset.multiplier;
      let multiplier = toggleCont.dataset.setMultiplier;
      changeYield(multiplier);
      changeIngredients(multiplier);
    });
  });

// functions to change yield and ingredients based on multiplier

function changeYield(multiplier) {
  if (yieldBase.children[0].firstChild.data.includes('servings')) {
    const servings = modifyNumbers(yieldBase.dataset.servings, multiplier);
    yieldBase.innerHTML = `Yields: <span>` + servings + ` servings</span>`;
  } else {
    const servings = modifyNumbers(yieldBase.dataset.servings, multiplier);
    yieldBase.innerHTML = `Yields: <span>` + servings + `</span>`;
  }
}

function changeIngredients(multiplier) {
  ingredientContainer.forEach((element) => {
    const span = element.firstChild.firstChild;
    if (span.dataset.amountdecimal != 'null') {
      const adjustedAmount = span.dataset.amountdecimal * multiplier;
      span.innerText = toFraction(adjustedAmount, {
        useUnicodeVulgar: true,
      });
    }
  });
}

function modifyNumbers(text, multiplier) {
  // This function increases the values in a string containing numbers and potentially other text, considering fractions.

  const regex = /(\d+(?:\.\d*)?)([^\d]*|$)/g;

  return text.replace(regex, (match, numberStr, suffix) => {
    const number = toDecimal(parseFloat(numberStr));
    // const result = toDecimal(value); // Use toDecimal for decimal output
    const value = number * multiplier;

    // Use toDecimal or toFraction based on preference
    const result = toFraction(value, {
      useUnicodeVulgar: true,
    });
    // Use toFraction for fraction output (uncomment if desired)

    return result + suffix;
  });
}

// Examples with expected outputs:
// console.log(modifyNumbers("I have 2 cups of coffee", 2)); // Output: I have 4.0 cups of coffee (rounded to 1 decimal)
// console.log(modifyNumbers("The answer is 42", 3)); // Output: The answer is 126
// console.log(modifyNumbers("Buy 5 pounds of apples", 0.5)); // Output: Buy 2.5 pounds of apples
// console.log(modifyNumbers("1/4 cups", 2)); // Output: 1/2 cups
// console.log(modifyNumbers("3.14", 2)); // Output: 6.28 (rounds to 1 decimal)
